.sub-menu .menu-item {
  cursor: pointer;
  height: 45px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  text-decoration: none !important;
  padding: 0 5px;
  margin: 0 10px;
  color: #000000;
  font-weight: 400;
  text-align: center;
}

.sub-menu .menu-item:hover {
  color: #212121;
  //border-bottom: 2px solid #e02b1f;
}

.sub-menu .menu-item.active {
  border-bottom: 2px solid #e02b1f;
  color: #212121;
}

.szh-menu {
    box-shadow: none;
    border: 1px solid #d9d9d9;
}

.szh-menu__item--active {
    background-color: #d4454f;
}
