


.image-widget-item {
    background: no-repeat 50%;
    background-size: cover;
    border-radius: 3px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0;
    vertical-align: top;
    display: inline-block;
}


.cart-content {
    flex: 1 1 auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.cart-content .name h2 {
    //ont-size 12px
}

.cart-actions {
    flex: 0 0 auto;
}


.button-checkout {
    border-radius: 10px 0 0 10px !important;
    background-color: #c1caec !important;
    opacity: 0.8 !important
}

.button-checkout:hover, .button-checkout:active, .button-checkout:focus {
  opacity: 1 !important
}


@media screen and (max-width: 767px) {
    .cart-content .name h2 {
        font-size 18px
    }
}
