.szh-menu-button{padding:0;background:rgba(0,0,0,0);border:none}

.editor-dialog {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.editor-dialog .header {
  width: 100%;
  height: 50px;
  min-height: 50px;
  padding: 0 20px;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
}
.editor-dialog .body {
  max-width: 740px;
  width: 100%;
  padding: 20px;
  font-size: 21px;
  box-sizing: border-box;
}
.dialog-fake.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: fixed;
  touch-action: none;
  background-color: rgba(0,0,0,0.5);
  -webkit-tap-highlight-color: transparent;
}


.image-widget-item {
  background: no-repeat 50%;
  background-size: cover;
  border-radius: 3px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0;
  vertical-align: top;
  display: inline-block;
}
.cart-content {
  flex: 1 1 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.cart-actions {
  flex: 0 0 auto;
}
.button-checkout {
  border-radius: 10px 0 0 10px !important;
  background-color: #c1caec !important;
  opacity: 0.8 !important;
}
.button-checkout:hover,
.button-checkout:active,
.button-checkout:focus {
  opacity: 1 !important;
}
@media screen and (max-width: 767px) {
  .cart-content .name h2 {
    font-size: 18px;
  }
}

.cart-widget {
  background-color: #fff !important;
  border-left: 1px solid #eee !important;
}

@charset "UTF-8";
.szh-menu-container {
  position: relative;
  width: 0px;
  height: 0px;
}

.szh-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  width: max-content;
  position: absolute;
  z-index: 100;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.szh-menu:focus {
  outline: none;
}
.szh-menu--state-closed {
  display: none;
}
.szh-menu__arrow {
  box-sizing: border-box;
  width: 0.75rem;
  height: 0.75rem;
  background-color: #fff;
  border: 1px solid transparent;
  border-left-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: -1;
}
.szh-menu__arrow--dir-left {
  right: -0.375rem;
  transform: translateY(-50%) rotate(135deg);
}
.szh-menu__arrow--dir-right {
  left: -0.375rem;
  transform: translateY(-50%) rotate(-45deg);
}
.szh-menu__arrow--dir-top {
  bottom: -0.375rem;
  transform: translateX(-50%) rotate(-135deg);
}
.szh-menu__arrow--dir-bottom {
  top: -0.375rem;
  transform: translateX(-50%) rotate(45deg);
}
.szh-menu__item {
  cursor: pointer;
}
.szh-menu__item:focus {
  outline: none;
}
.szh-menu__item--hover {
  background-color: #ebebeb;
}
.szh-menu__item--focusable {
  cursor: default;
  background-color: inherit;
}
.szh-menu__item--disabled {
  cursor: default;
  color: #aaa;
}
.szh-menu__submenu {
  position: relative;
}
.szh-menu__group {
  box-sizing: border-box;
}
.szh-menu__radio-group {
  margin: 0;
  padding: 0;
  list-style: none;
}
.szh-menu__divider {
  height: 1px;
  margin: 0.5rem 0;
  background-color: rgba(0, 0, 0, 0.12);
}

.szh-menu-button {
  box-sizing: border-box;
}

.szh-menu {
  user-select: none;
  color: #212529;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.133), 0 0.6px 2px rgba(0, 0, 0, 0.1);
  min-width: 10rem;
  padding: 0.5rem 0;
}
.szh-menu__item {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.375rem 1.5rem;
}
.szh-menu-container--itemTransition .szh-menu__item {
  transition-property: background-color, color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.szh-menu__item--active {
  color: #fff;
  background-color: #007bff;
}
.szh-menu__item--type-radio {
  padding-left: 2.2rem;
}
.szh-menu__item--type-radio::before {
  content: "\25CB";
  position: absolute;
  left: 0.8rem;
  top: 0.55rem;
  font-size: 0.8rem;
}
.szh-menu__item--type-radio.szh-menu__item--checked::before {
  content: "\25CF";
}
.szh-menu__item--type-checkbox {
  padding-left: 2.2rem;
}
.szh-menu__item--type-checkbox::before {
  position: absolute;
  left: 0.8rem;
}
.szh-menu__item--type-checkbox.szh-menu__item--checked::before {
  content: "\2714";
}
.szh-menu__submenu > .szh-menu__item {
  padding-right: 2.5rem;
}
.szh-menu__submenu > .szh-menu__item::after {
  content: "\276F";
  position: absolute;
  right: 1rem;
}
.szh-menu__header {
  color: #888;
  font-size: 0.8rem;
  padding: 0.2rem 1.5rem;
  text-transform: uppercase;
}

@keyframes szh-menu-show-slide-left {
  from {
    opacity: 0;
    transform: translateX(0.75rem);
  }
}
@keyframes szh-menu-hide-slide-left {
  to {
    opacity: 0;
    transform: translateX(0.75rem);
  }
}
@keyframes szh-menu-show-slide-right {
  from {
    opacity: 0;
    transform: translateX(-0.75rem);
  }
}
@keyframes szh-menu-hide-slide-right {
  to {
    opacity: 0;
    transform: translateX(-0.75rem);
  }
}
@keyframes szh-menu-show-slide-top {
  from {
    opacity: 0;
    transform: translateY(0.75rem);
  }
}
@keyframes szh-menu-hide-slide-top {
  to {
    opacity: 0;
    transform: translateY(0.75rem);
  }
}
@keyframes szh-menu-show-slide-bottom {
  from {
    opacity: 0;
    transform: translateY(-0.75rem);
  }
}
@keyframes szh-menu-hide-slide-bottom {
  to {
    opacity: 0;
    transform: translateY(-0.75rem);
  }
}
.szh-menu--state-opening.szh-menu--dir-left {
  animation: szh-menu-show-slide-left 0.15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-left {
  animation: szh-menu-hide-slide-left 0.15s ease-in;
}

.szh-menu--state-opening.szh-menu--dir-right {
  animation: szh-menu-show-slide-right 0.15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-right {
  animation: szh-menu-hide-slide-right 0.15s ease-in;
}

.szh-menu--state-opening.szh-menu--dir-top {
  animation: szh-menu-show-slide-top 0.15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-top {
  animation: szh-menu-hide-slide-top 0.15s ease-in;
}

.szh-menu--state-opening.szh-menu--dir-bottom {
  animation: szh-menu-show-slide-bottom 0.15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-bottom {
  animation: szh-menu-hide-slide-bottom 0.15s ease-in;
}

.sub-menu .menu-item{cursor:pointer;height:45px;border-top:2px solid rgba(0,0,0,0);border-bottom:2px solid rgba(0,0,0,0);text-decoration:none !important;padding:0 5px;margin:0 10px;color:#000;font-weight:400;text-align:center}.sub-menu .menu-item:hover{color:#212121}.sub-menu .menu-item.active{border-bottom:2px solid #e02b1f;color:#212121}.szh-menu{box-shadow:none;border:1px solid #d9d9d9}.szh-menu__item--active{background-color:#d4454f}
