




.editor-dialog
  width 100%
  display flex
  flex-direction column
  align-items center

  .header
    width 100%
    height 50px
    min-height 50px
    padding 0 20px
    display flex
    justify-content flex-end
    border-bottom 1px solid #eee
    box-sizing border-box

  .body
    max-width 740px
    width 100%
    padding 20px
    font-size 21px
    box-sizing border-box


.dialog-fake.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: fixed;
  touch-action: none;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}
